import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import * as amplitude from "@amplitude/analytics-browser"
import ChannelService from "./src/service/ChannelTalk"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  // amplitude
  const amplitudeApiKey = process.env.GATSBY_AMPLITUDE_API_KEY
  amplitude.init(amplitudeApiKey)

  // channelTalk
  ChannelService.loadScript()
  ChannelService.boot({
    pluginKey: "915c9e8f-0dbe-49f3-8fb5-96e0d9675e16",
  })

  return <>{element}</>
}
