exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-customer-tsx": () => import("./../../../src/pages/customer.tsx" /* webpackChunkName: "component---src-pages-customer-tsx" */),
  "component---src-pages-groomers-index-tsx": () => import("./../../../src/pages/groomers/index.tsx" /* webpackChunkName: "component---src-pages-groomers-index-tsx" */),
  "component---src-pages-groomers-seminar-20240623-tsx": () => import("./../../../src/pages/groomers/seminar_20240623.tsx" /* webpackChunkName: "component---src-pages-groomers-seminar-20240623-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-noti-tsx": () => import("./../../../src/pages/noti.tsx" /* webpackChunkName: "component---src-pages-noti-tsx" */),
  "component---src-pages-platform-tsx": () => import("./../../../src/pages/platform.tsx" /* webpackChunkName: "component---src-pages-platform-tsx" */),
  "component---src-pages-ppa-download-tsx": () => import("./../../../src/pages/ppa-download.tsx" /* webpackChunkName: "component---src-pages-ppa-download-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-reservation-tsx": () => import("./../../../src/pages/reservation.tsx" /* webpackChunkName: "component---src-pages-reservation-tsx" */),
  "component---src-pages-safety-tsx": () => import("./../../../src/pages/safety.tsx" /* webpackChunkName: "component---src-pages-safety-tsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */)
}

